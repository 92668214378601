var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_vm.reps && _vm.reps.length > 0 ? _vm._l(_vm.reps, function (user, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(user.email) + " "), _c('div', {
      staticClass: "repAdmin-action"
    }, [_vm.isRootRep && _vm.type == 'all' ? _c('custom-button', {
      attrs: {
        "title": user.status == 'S' ? 'Activate' : 'Suspend',
        "className": user.status == 'S' ? 'activate-btn' : 'suspend-btn',
        "haveText": ""
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.toggleRep(user.id, user.status);
        }
      }
    }) : _vm._e(), _vm.isRootRep ? _c('custom-button', {
      attrs: {
        "title": "Delete",
        "className": "deleteEmail",
        "iconClass": "far fa-trash-alt"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.deleteRep(user.id);
        }
      }
    }) : _vm._e()], 1)]);
  }) : [_c('li', [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("No " + _vm._s(_vm.type) + " reps found.")])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }