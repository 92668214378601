<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="row justify-content-md-center">
        <div class="col-xxl-5 col-xl-6 col-lg-7 col-md-8">
          <div class="repAdmin-card">
            <div class="repAdmin-head text-center">
              <h1>Rep Administrators</h1>
              <custom-button
                v-if="addBtn"
                iconClass="fas fa-plus"
                className="repAdmin-btn"
                title="Add Rep"
                @onClicked="$bvModal.show('addRep')"
              />
              <p>
                Rep Admins are additional accounts that you can add, that will
                have access to your dashboard.
              </p>
            </div>
            <div class="repAdmin-body">
              <h3>Users</h3>
              <rep-admin-list-content
                :reps="activeReps"
                type="all"
                @onSuccess="getAll"
              />
              <hr />
              <h3>Pending Users</h3>
              <rep-admin-list-content
                :reps="pendingReps"
                type="pending"
                @onSuccess="getAll"
              />
            </div>
            <rep-admin-add @onSuccess="getAll" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import RepAdminAdd from "./RepAdminAdd.vue";
import RepAdminListContent from "./partials/RepAdminListContent.vue";
export default {
  name: "RepAdminList",
  components: { RepAdminAdd, RepAdminListContent },
  data: () => ({
    activeReps: [],
    pendingReps: [],
    addBtn: false,
    breadcrumbItems: [{ title: "Rep Administrators", active: true }],
  }),
  methods: {
    getAll() {
      const url = `v2/get-reps-admin-details`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.addBtn = data.show_add_user_button;
          this.activeReps = data.user_list;
          this.pendingReps = data.pending_user_list;
        })
        .catch(() => {
          this.addBtn = false;
          this.activeReps = [];
          this.pendingReps = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
<style src="@/assets/css/repadmin.css"></style>