var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "row justify-content-md-center"
  }, [_c('div', {
    staticClass: "col-xxl-5 col-xl-6 col-lg-7 col-md-8"
  }, [_c('div', {
    staticClass: "repAdmin-card"
  }, [_c('div', {
    staticClass: "repAdmin-head text-center"
  }, [_c('h1', [_vm._v("Rep Administrators")]), _vm.addBtn ? _c('custom-button', {
    attrs: {
      "iconClass": "fas fa-plus",
      "className": "repAdmin-btn",
      "title": "Add Rep"
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.$bvModal.show('addRep');
      }
    }
  }) : _vm._e(), _c('p', [_vm._v(" Rep Admins are additional accounts that you can add, that will have access to your dashboard. ")])], 1), _c('div', {
    staticClass: "repAdmin-body"
  }, [_c('h3', [_vm._v("Users")]), _c('rep-admin-list-content', {
    attrs: {
      "reps": _vm.activeReps,
      "type": "all"
    },
    on: {
      "onSuccess": _vm.getAll
    }
  }), _c('hr'), _c('h3', [_vm._v("Pending Users")]), _c('rep-admin-list-content', {
    attrs: {
      "reps": _vm.pendingReps,
      "type": "pending"
    },
    on: {
      "onSuccess": _vm.getAll
    }
  })], 1), _c('rep-admin-add', {
    on: {
      "onSuccess": _vm.getAll
    }
  })], 1)])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }