<template>
  <ul>
    <template v-if="reps && reps.length > 0">
      <li v-for="(user, index) in reps" :key="index">
        {{ user.email }} 
        <!-- <span v-if="type == 'all'">
          {{user.status == 'S' ? 'Suspend' : 'Active'}}
        </span> -->
        <div class="repAdmin-action">
          <custom-button
            v-if="isRootRep && type == 'all'"
            :title="user.status == 'S' ? 'Activate' : 'Suspend'"
            :className="user.status == 'S' ? 'activate-btn' : 'suspend-btn'"
            haveText
            @onClicked="toggleRep(user.id, user.status)"
          />
          <custom-button
            v-if="isRootRep"
            title="Delete"
            className="deleteEmail"
            iconClass="far fa-trash-alt"
            @onClicked="deleteRep(user.id)"
          />
        </div>
      </li>
    </template>
    <template v-else>
      <li>
        <p class="text-center">No {{ type }} reps found.</p>
      </li>
    </template>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
import Api from "@/resource/Api";
export default {
  props: {
    reps: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ isRootRep: "rootUser" }),
  },
  methods: {
    toggleRep(repId, status) {
      const url = `v2/activate-deactivate-agent-user`;
      const [text, value] = status == "S" ? ["Activate", 1] : ["Suspend", 0];
      this.$confirm({
        title: `${text} Rep`,
        message: `Do you want to ${text.toLowerCase()} this rep?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let data = { agent_user_id: repId, activate: value };
            Api.post(url, data)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    deleteRep(repId) {
      const url = `v2/delete-agent-user/${repId}`;
      this.$confirm({
        title: "Delete Rep",
        message: `Do you want to delete this rep?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>