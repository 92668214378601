<template>
  <!-- User Add Pop up -->
  <b-modal id="addRep" centered size="md" no-close-on-backdrop>
    <template #modal-header>
      <div class="addRep-header">
        <h4>Add Rep User</h4>

        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="addRep-form">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.stop.prevent="handleSubmit(create)"
            ref="addRepAdminContainer"
          >
            <input-text
              :rules="{ required: true, email:true, neverbounce: true }"
              labelFor="email"
              labelName="Email Address"
              :vmodel.sync="form.email"
              formGroupClass="form-group mb-3"
              inputType="email"
            />

            <input-text
              :rules="{ required: true, digits: 10,clearoutPhone:true }"
              labelFor="phone"
              labelName="Phone"
              :vmodel.sync="form.phone"
              formGroupClass="form-group mb-3"
              inputType="number"
            />
            <div class="form-group text-center mb-3 mt-3">
              <b-button class="univ-btn" type="submit">
                {{ isCreate ? "Creating..." : "Create" }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "RepAdminAdd",
  data: () => ({
    form: {
      email: "",
      phone: "",
    },
    isCreate: false,
  }),
  methods: {
    closeModal() {
      this.reset();
      this.$emit("onCloseModal");
    },
    reset() {
      this.form = {
        email: "",
        phone: "",
      };
      this.$refs.observer.reset();
      this.$bvModal.hide("addRep");
    },
    create() {
      const url = "v2/add-agent-user";
      let loader = this.$loading.show({
        container: this.$refs.addRepAdminContainer,
      });
      this.isCreate = true;
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.reset();
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCreate = false;
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
  },
};
</script>